import React, { useEffect, useState } from 'react'
import './ReportBug.css'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../firebase'
import { useSelector } from 'react-redux'

function ReportBug() {
    const [report, setReport] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)

    const ud = useSelector(state=> state.userData.userData)

    const handleBugReport = async () => {
        if(report.trim() !== '') {
            const bugRepRef = collection(db, 'bugs')

            const d = `${new Date().getDate()} ${new Date().toLocaleString('en', {month: 'long'})} ${new Date().getFullYear()}`

            await addDoc(bugRepRef, {
                reporter: ud.name,
                reportedOn: d,
                report: report,
                status: 'unsolved'
            })

            setReport('')
            setIsSubmitted(true)
        } else {
            alert('Please describe the bug before submitting!')
        }
    }

    useEffect(()=> {
        let updateIsSubmitted
        if(isSubmitted) {
            updateIsSubmitted = setTimeout(()=> {
                setIsSubmitted(false)
            }, 4000)
        }

        return ()=> {
            if(updateIsSubmitted) {
                clearTimeout(updateIsSubmitted)
            }
        }
    }, [isSubmitted])


  return (
    <div className='report_bug_cont'>
        <h2 className='report_bug_h2'>Report Bugs to the Technical Team</h2>

        {!isSubmitted && <div style={{display: 'flex', flexDirection: 'column'}}>
            <textarea className='report_bug_txt_area' value={report} onChange={e=> setReport(e.target.value)}></textarea>
            <button className='report_bug_btn' onClick={handleBugReport}>Submit</button>
        </div>}


        {isSubmitted && <p className='report_bug_submitted'>Thank you for your report. Our technical team will take action as soon as possible!</p>}
    </div>
  )
}

export default ReportBug