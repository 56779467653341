import React, { useEffect, useState } from 'react'
import './AllCommunications.css'
import { useSelector } from 'react-redux'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase'
import { Link } from 'react-router-dom'


function AllCommunications() {
    const ud = useSelector(state=> state.userData.userData)

    const [allRooms, setAllRooms] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [isNames, setIsNames] = useState(true)

    useEffect(()=> {
      const roomsRef = collection(db, 'rooms')

      const getRooms = async () => {
        const data = await getDocs(roomsRef)
        let arr = []

        data.forEach(r=> {
          if(!allRooms.includes(r.id)) {
            arr.push(r.id)
          }
        })

        arr.filter((item, index)=> {
          return arr.indexOf(item)  == index
        })

        if(allRooms.length === 0) setAllRooms(arr)
      }

      getRooms()
    }, [])


    useEffect(()=> {
      const usersRef = collection(db, 'users')

      let arr = []

      const getUsers = async () => {
        const data = await getDocs(usersRef)

        data.forEach(u=> {
          arr.push({data:u.data(), cn: u.id})
        })

        arr.filter((item, index)=> {
          return arr.indexOf(item) == index
        })

        setAllUsers(arr)
      }

      if(allUsers.length === 0) getUsers()
    }, [])


    let roomObj = []
    
    allRooms.forEach(r=> {
      let names = []
      allUsers.map(u=> {
        const regex = new RegExp(u.cn, 'ig')

        if(regex.test(r)) {
          names.push(u.data.name)
        }
      })

      roomObj.push({
        names: names,
        roomCN: r
      })
    })



    
    let renC = allRooms.map(r => {
      return (
         <Link to={`/all-communications/${r}`} style={{textDecoration: 'none'}}>
          <div className='allCommunications__room_c'>{r}</div>
         </Link>
      )
    })

    let renN = roomObj.map(r=> {
      return (
        <Link to={`/all-communications/${r.roomCN}`} style={{textDecoration: 'none'}}>
          <div className='allCommunications__room_names'>{r.names.join(' & ')}</div>
        </Link>
      )
    })


  return (
    <div>
        {ud?.role !== 'client' && 
        <div>
            <div className='allCommunications__title_div'><h2 className='allCommunications__h2'>All Rooms</h2> <p onClick={()=> setIsNames(prev=> !prev)}>Show {isNames? 'Codes': 'Names'}</p></div>
            {isNames? renN: renC}
        </div>}
    </div>
  )
}

export default AllCommunications