import React, { useEffect, useState } from 'react'
import './Reports.css'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { auth, storage } from '../firebase'
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage'
import Loading2 from './Loading2'


function ReportsName(props) {
    const ud = useSelector(state=> state.userData.userData)
    const part = window.location.pathname.split('/').pop().replace('%20', ' ').trim().toLowerCase()
    const [reports, setReports] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const regex = /%2..*%2F(.*?)\?alt/
    let arr
    let ren

    if(ud?.role !== 'client') {
        props?.prog?.map(cl=> {
            if(cl.name.toLowerCase() === part) {
                arr = cl.services
                
                ren = arr?.map(s => {
                return (
                    <Link to={`/reports/${part}/${s}`} style={{textDecoration: 'none', color: 'black'}}>
                        <div className='reportsName__cont'>
                        <p className='reportsName__para'>{s.replace(/seo/i, 'SEO')}</p>
                        </div>
                    </Link>
                )
                })
            }
        })
    }

    

    useEffect(()=> {
        if(ud?.role == 'client') {
            // setIsLoading(true)
            const service = part.replaceAll('-', ' ')
            const reportsRef = ref(storage, `reports/${auth.currentUser.uid}/${service}/`)
            listAll(reportsRef).then(res=> res.items.forEach(item=> {
                getDownloadURL(item).then(url => {
                    if(!reports.includes(url)) {
                        setReports(prev=> [...prev, url])
                    }
                })
            }))
            
        }
    }, [ud])

    useEffect(()=> {
        const updateIsLoading = setTimeout(()=> {
            setIsLoading(false)
        }, 2000)

        return ()=> {
            clearTimeout(updateIsLoading)
        }
    }, [])


    
    if(ud?.role == 'client') {
        let arr2 = []
        reports?.forEach(x => {
            if(!arr2.includes(x)) {
                arr2.push(x)
            }

            
        })

        arr2.sort(function(x, y) {
                return Number(x.match(regex)[1].replaceAll('%20', ' ').split('-')[3]) - Number(y.match(regex)[1].replaceAll('%20', ' ').split('-')[3])
            })


            ren = arr2?.reverse().map(rep => {
                let repName = rep.match(regex)[1].replaceAll('%20', ' ').split('-').slice(0, -1).join('-')
                let byName = rep.match(regex)[1].replaceAll('%20', ' ').split('-').pop()
                let fullRepName = repName.replace(/seo/i, 'SEO')

                return (
                <a href={rep} target='_blank' className='reports__link'><div className='reports__box'>
                <span className='reportsName__fullRepName'>{fullRepName} <span className='reportsName__byName'>{byName}</span></span>
                </div>
                </a>
            )
        })
    }

    let title

    if(ud?.role == 'client') {
        let x = part.replaceAll('-', ' ')
        // let y = x.split(' ').pop().toUpperCase()
        // let z = x.split(' ').slice(0, -1).join(' ')
        title = x.replace(/seo/i, 'SEO')
    }


    return (
    <div>
        {ud?.role !== 'client' && <a href='/reports' style={{textDecoration: 'none'}}><p className='breadcrumbs'>
            {'<< Back'}
        </p></a>}
        <h2 className='reports__h2'>{ud?.role !== 'client'?'Select Services': `Reports for ${title}`}</h2>
        {(ud?.role == 'client' && isLoading)? <Loading2 />: (ud?.role == 'client' && ren.length !== 0)? ren : ud?.role == 'client' && <span style={{marginLeft: '15px'}}>The specialist has not yet uploaded any reports</span>}
        {(ud?.role == 'specialist' && isLoading)? <Loading2 />: (ud?.role == 'specialist') && ren}
    </div>
  )
}

export default ReportsName