import React from 'react'
import img404 from '../images/404.png'
import './NotFound.css'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div>
        <img src={img404} className='img404'/>
        <h1>Page Not Found<span className='notFoundSpan'>!</span></h1>
        <Link to='/account'>
            <button className='notFoundBTN'>Go to Home Page</button>
        </Link>
    </div>
  )
}

export default NotFound