import React, { useEffect } from 'react'
import './Reports.css'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { csnActions } from '../store'
import Loading from './Loading'



function Reports(props) {
    const [file, setFile] = useState(null)
    const ud = useSelector(state=> state.userData.userData)

    const [cPosition, setCPosition] = useState({x: 0, y: 0})

    const handleOnMouseMove = e => {
        const { clientX, clientY, currentTarget } = e
        const rect = currentTarget.getBoundingClientRect()

        setCPosition({
            x: clientX - rect.left,
            y: clientY - rect.top
        })
    }




    const fontColor = {
        color: 'blue'
    }

    const linkStyle = {
        textDecoration: 'none',
        color: 'black'
    }
    
    
    let ren 
    let cren

    if(ud?.role !== 'client') {
        ren = props?.prog?.map((cl, i) => {
            return (
                <Link to={`/reports/${cl.name}`} style={linkStyle} key={i}>
                <div className='reports__cont' onMouseMove={e=> handleOnMouseMove(e)} style={{display: 'flex', flexDirection: 'row', marginRight: '20px', '--mouse-x': `${cPosition.x}px`, '--mouse-y': `${cPosition.y}px`}}>
                    <p className='reports__name'>{cl.name}</p>
                    <p className='reports__services'>{cl.services.join(' | ').replace(/seo/i, 'SEO')}</p>
                </div>
                </Link>
            )
        })
    }

    if(ud?.role == 'client') {
        let servicesArr = ud?.services.filter((v, i, s)=> s.indexOf(v) === i)
        cren = servicesArr?.map((s, i)=> {
            return (
                <Link to={`/reports/${s.replaceAll(' ', '-')}`} style={linkStyle} key={i}>
                    <div className='reports__cont' onMouseMove={e=> handleOnMouseMove(e)} style={{'--mouse-x': `${cPosition.x}px`, '--mouse-y': `${cPosition.y}px`}}>
                        <p className='reports__name'>{s.replace(/seo/i, 'SEO')}</p>
                    </div>
                </Link>
            )
        })
    }

    

    if(auth.currentUser) {
        return (
            <div>
                <h2 className='reports__h2'>{ud?.role !== 'client'?'Reports' : 'Select a Service'}</h2>
                {ud?.role !== 'client'? <div style={{display: 'flex', flexDirection: 'column'}}>{ren}</div>: <div style={{display: 'flex', flexDirection: 'column'}}>{cren}</div>}

            </div>
        )
    } else {
        
        return (
            <Loading />
        )
    }
}

export default Reports