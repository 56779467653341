import './App.css';
import Progress from './components/Progress';
import Archive from './components/Archive';
import Communications from './components/Communications';
import Reports from './components/Reports';
import Messages from './components/Messages';
import Login from './components/Login';
import NotFound from './components/NotFound';
import { auth, db } from './firebase'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, query, where, getDocs, collection, onSnapshot } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { authStateActions, acdActions, userDataActions, progressDataActions } from './store';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import ServiceProgress from './components/ServiceProgress';
import SpecProgress from './components/SpecProgress';
import ReportsName from './components/ReportsName';
import DisplayReports from './components/DisplayReports';
import AddUser from './components/AddUser';
import AllCommunications from './components/AllCommunications';
import DisplayAllMessages from './components/DisplayAllMessages';
import ClientsDueLimits from './components/ClientsDueLimits';
import SubscriptionSettings from './components/SubscriptionSettings';
import UpdateUser from './components/UpdateUser';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';
import ReportBug from './components/ReportBug';
import DisplayBugReports from './components/DisplayBugReports';


function App() {

  const [user, setUser] = useState(null)
  const [userState, setUserState] = useState(false)
  const [notes, setNotes] = useState([])
  const [notesListener, setNotesListener] = useState(0)
  const [prog, setProg] = useState(null)
  const [cprog, setCProg] = useState(null)

  let ciduids = []

  const dispatch = useDispatch()

  useEffect(()=> {
    if(auth.currentUser) {
      const userRef = doc(db, 'users', auth.currentUser.uid)
      let data
      const getUserData = async () => {
        data = await getDoc(userRef)
        dispatch(userDataActions.setUserData(data.data()))
        dispatch(acdActions.setACD(data.data().assignedClients))
      }
        
      getUserData()
    }
  }, [userState])

  useEffect(()=> {
    if(auth.currentUser) {
      dispatch(authStateActions.setAuthState())
    }
  }, [userState])


  useEffect(()=> {
    const checkUserState = onAuthStateChanged(getAuth(), async u => {
          setUserState(!!u)
          
      })

    return () => checkUserState()
  }, [])

  useEffect(()=> {
    if(auth.currentUser) {
      const userRef = doc(db, 'users', auth.currentUser.uid)
      let data
      const getUserData = async () => {
        data = await getDoc(userRef)
        setUser(data.data())
      }
        
      getUserData()
    }
  }, [userState])


  if(user?.role === 'client') {
          user?.assignedMembers.forEach(member => {
            ciduids.push({
              name: member.name,
              ch: auth.currentUser?.uid + member.cn
            })
          })
        } else if(user?.role !== 'client') {
          user?.assignedClients.forEach(client => {
            ciduids.push({
              name: client.name,
              ch: client.cn+ auth.currentUser?.uid
            })
          })
        }

  useEffect(()=> {
    if(user) {

      let arr = []

      const checkNotes = async (ciduid) => {
        const messagesRef = collection(db, 'rooms', ciduid.ch, 'messages')

        const q = query(messagesRef, where('seen', '==', false))
          onSnapshot(q, snapshot => {
            

            snapshot.docs.forEach(doc => {
              if(doc.data().uid !== auth.currentUser.uid) {
                arr.push({...doc.data(), id: doc.id})
              }
            })

            let cleanArr = arr?.filter((obj, i, n) => i === n.findIndex(o=> o.id === obj.id))

            setNotes(cleanArr)
          })
      }


      ciduids.forEach(c => {
        checkNotes(c)
      })
    }
  }, [user, userState, notesListener, auth.currentUser])



  useEffect(()=> {
    if(user?.role == 'client') {
        const pRef = doc(db, 'progress', auth.currentUser?.uid)

        const getProg = async () => {
            const data = await getDoc(pRef)
            setProg(data.data())
            dispatch(progressDataActions.setProgressData(data.data()))
        }

        getProg()
    } else if (user?.role !== 'client') {
      setProg(user?.assignedClients)
      let arrP = []
      const clProg = async (_cn, _name) => {
        const progRef = doc(db, 'progress', _cn)
        let data = await getDoc(progRef)
        arrP.push({cn: _cn, prog: data.data(), name: _name})
      }
      setCProg(arrP)
      user?.assignedClients.map(c => {
        clProg(c.cn, c.name)
      })
    }
}, [user])





  return (
    <Router>
      <div className="App">
        <Header role={user?.role} name={user?.name}/>
        <div style={{display: 'flex', height: '90%'}}>
        <Sidebar notes={notes}/>
          <Routes>

              <Route path='/progress' element={<Progress ml={()=> setNotesListener(prev=> prev + 1)} prog={prog}/>} />

              <Route path='/progress/:service' element={<ServiceProgress prog={prog} cProg={cprog}/>} />

              <Route path='/progress/:service/:sPart' element={<SpecProgress cProg={cprog} ud={user}/>} />

              <Route path='/archive' element={<Archive />} />

              <Route path='/communications' element={<Communications notes={notes}/>} />

              <Route path='/reports' element={<Reports prog={prog}/>} />

              <Route path='/reports/:name' element={<ReportsName prog={prog}/>} />

              <Route path='/reports/:name/:service' element={<DisplayReports />} />

              <Route path='/communications/:part' element={<Messages ml={()=> setNotesListener(prev=> prev + 1)} notes={notes}/>} />

              <Route path='/add-user' element={<RequireAuth _role='manager' _comp='AddUser' />} />

              <Route path='/all-communications' element={<RequireAuth _role='manager' _comp='AllCommunications' />} />

              <Route path='/all-communications/:rcn' element={user?.role == 'manager'? <DisplayAllMessages /> : <Unauthorized />} />

              <Route path='/clients-due-limits' element={<RequireAuth _role='manager' _comp='ClientsDueLimits' />} />

              <Route path='/employee-center' element={<RequireAuth _role='manager' _comp='EmployeeCenter' />} />

              <Route path='/subscription-settings' element={<SubscriptionSettings />} />

              <Route path='/update-user' element={<RequireAuth _role='manager' _comp='UpdateUser' />} />

              <Route path='/report-bug' element={user?.role === 'manager'? <DisplayBugReports /> : <ReportBug />} />
          
              <Route path='/' element={<Login />} />

              <Route path='/login' element={<Login />} />

              <Route path='*' element={<NotFound />} />
              
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
