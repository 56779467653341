import React from 'react'
import './Header.css'
import { auth } from '../firebase'
import { signOut } from 'firebase/auth'
import logo2 from '../images/logo2.png'
import { useSelector } from 'react-redux'


function Header(props) {

  const cAuth = useSelector(state => state.auth.authState)

    async function logout() {
        await signOut(auth)
        sessionStorage.removeItem('authToken')

        window.location.href = '/login'
    }

    // const logo = "https://improwth.com/wp-content/uploads/2021/03/Monochrome-on-Transparent.png"
if(cAuth) {
  return (
    <header>
        <img src={logo2} id="logo" alt="Logo" />
        <p>{props.role} Account  | Welcome {props.name}</p>

        <div class="logout-cont" onClick={logout}><button class="logout">Logout</button></div>
    </header>
  )}
}

export default Header