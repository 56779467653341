import React, { useEffect } from 'react'
import './Loading.css'
import { useNavigate } from 'react-router-dom'

function Loading() {

  const navigate = useNavigate()

  function loginPage() {
    navigate('/login')
  }

  useEffect(() => {
    const timer = setTimeout(loginPage, 7000)

    return () => clearTimeout(timer)
  }, [])
  return (
    <div className='loading'>
        <div className='ring'></div>
        <span className='loading-text'>Loading ...</span>
    </div>
  )
}

export default Loading