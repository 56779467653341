import React, { useEffect } from 'react'
import './Archive.css'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { authStateActions, csnActions } from '../store'
import Loading from './Loading'
import checkmark from '../images/green_checkmark.png'


function Archive() {

    const ud = useSelector(state=> state.userData.userData)

    let archArr 
    if(ud) {
        archArr = [...ud?.archive]
    }

    archArr?.sort(function (a, b) {
        return a.n - b.n
    })

    
    const result = archArr?.reduce((acc, current) => {
        const existingItem = acc.find(item => item.service === current.service);
      
        if (existingItem) {
        
          const maxN = existingItem.lps.reduce((max, lp) => Math.max(max, lp.n), 0);
          const updatedLps = current.lps.map(lp => ({ ...lp, n: maxN + lp.n }));
          existingItem.lps = existingItem.lps.concat(updatedLps);
        } else {
        
          acc.push({ service: current.service, lps: [...current.lps] });
        }
      
        return acc;
      }, []);


      
    let ren = result?.map(arch=> {
        // let checkArch = Array.isArray(arch.lps)? arch.lps : Object.values(arch.lps)


        let lps = arch.lps?.slice().sort((x, y) => {
            return y.n - x.n
        })

        let checkDubLps = new Set()

        
        return (
            <div className='arch__cont_' style={arch.n%2 === 0? {backgroundColor: 'rgb(225, 225, 225)'}: {}}>
                
                <p className='arch__p_'>{arch.service.replace(/seo/i, 'SEO')}</p>
                {lps?.map(lp => {
                    if(!checkDubLps.has(lp.lp)) {
                        checkDubLps.add(lp.lp)
                    return (
                <div className='arch__lps_cont'>
                <p className='arch__p_content'></p>
                <img src={checkmark} className='arch__cm' alt='checkmark'/>
                <p className='arch__p_content'>{lp.lp}</p>
                <p className='arch__p_content'>{lp.price}</p>     
                </div>
                    )}
                })}
            </div>
        )
    })

    if(auth.currentUser) {
        return (
            <div style={{overflowY: 'scroll', overflowX: 'hidden'}}>
                <h2 className='arch__h2'>Archive</h2>
                <div className='arch__lps_cont_t' style={{marginBottom: '25px'}}>
                    <p className='arch__p arch__p_t' style={{marginRight: '50px'}}>Service</p>
                    <p className='arch__p arch__p_t'>Status</p>
                    <p className='arch__p arch__p_t'>Date</p>
                    <p className='arch__p arch__p_t'>Amount</p>
                </div>
                {ren}
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default Archive