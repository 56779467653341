import React from 'react'
import './SubscriptionSettings.css'
import { useSelector } from 'react-redux'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'

function SubscriptionSettings() {
    const ud = useSelector(state=> state.userData.userData)



    function deepCopy(obj) {
        if(obj == null || typeof obj !== 'object') return obj
      
        const newObj = {}
      
        for(const key in obj) {
          if(obj.hasOwnProperty(key)) {
            newObj[key] = deepCopy(obj[key])
          }
        }
      
        return newObj
      }

    const confirmSelect = async (service, _i) => {
        if(window.confirm(`Are you sure to cancel ${service}?`)) {
            const archRef = doc(db, 'users', auth.currentUser.uid)

            let specN

            ud?.archive.map(arch=> {
              if(arch.service === service && arch.status === 'active') {
                specN = arch.specialist
              }
            })

            let specCN

            if(specN) {ud?.assignedMembers.map(am=> {
              if(am.name === specN) {
                specCN = am.cn
              }
            })}

            const part0 = deepCopy(ud?.archive)
            let part5 = Array.isArray(part0)? part0: Object.values(part0)

            let part = part5.map(arch=> {
              if(!Array.isArray(arch.lps) && arch.lps) {
                arch.lps = Object.values(arch.lps)
              }

              return arch
            })

            let arrOfObj = []

            for(let arch of part) {
                if(arch.service === service) {
                    arch.status = 'canceled'
                    arrOfObj.push(arch)
                } else {
                    arrOfObj.push(arch)
                }
            }

            let aMembArr = []

            ud?.assignedMembers.map(am => {
              if(am.name === specN) {
                aMembArr.push({
                  name: am.name,
                  cn: am.cn,
                  canceled: true
                })
              } else {
                aMembArr.push(am)
              }
            })


            
            updateDoc(archRef, {archive: arrOfObj})


            const progRef = doc(db, 'progress', auth.currentUser.uid)

            let data0 = (await getDoc(progRef)).data()

            data0.progress.map(p=> {
              if(p.service === service) {
                p.status = 'inactive'
              }
            })


            const specRef = doc(db, 'users', specCN)

            let data1 = (await getDoc(specRef)).data()

            let assignedCls = []

            data1.assignedClients.map(cl => {
              if(cl.name === ud?.name) {
                if(cl.services.length > 1) {
                  let s = cl.services.filter(i=> i !== service)
                  assignedCls.push({
                    name: cl.name,
                    cn: cl.cn,
                    services: s
                  })
                }
              } else {
                assignedCls.push(cl)
              }
            })


            updateDoc(progRef, {progress: data0.progress})

            updateDoc(doc(db, 'users', auth.currentUser.uid), {assignedMembers: aMembArr})

            

            updateDoc(doc(db, 'rooms', auth.currentUser.uid+specCN), {canceled: true})


            updateDoc(specRef, {assignedClients: assignedCls})


            window.location.reload()
        } else {

        }
    }

    let ren = ud?.archive.map((s, i)=> {
        return (
            <div className='subSet__service_cont' style={i%2 === 0 ? {}: {backgroundColor: 'rgb(225, 225, 225)'}}>
                <p>{s.service.replace(/seo/i, 'SEO')}</p>
                <p style={s.status == 'active'? {color: 'rgb(117, 187, 13)'} : {color: 'gray'}}>{s.status === 'canceled'? 'Inactive': s.status}</p>
                {s.status === 'active'? <button onClick={()=> confirmSelect(s.service, i)}>Deactivate Service</button>: <span style={{color: 'gray'}}>Canceled</span>}
            </div>
        )
    })

  return (
    <div style={{overflowY: 'scroll', overflowX: 'hidden'}}>
        <h2 className='subSet__h2'>Subscription Settings</h2>
        <div className='subSet__service_cont' style={{marginBottom: '30px'}}>
                <p>Service</p>
                <p>Status</p>
                <p>Cancel Service</p>
        </div>
        {ren}
    </div>
  )
}

export default SubscriptionSettings