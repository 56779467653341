import React, { useEffect, useState } from 'react';
import './Accordion.css';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Accordion = ({ cls, _index, setCls, data }) => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection((prevOpenSection) => (prevOpenSection === index ? null : index));
  };

  
  ////

  const [newDL, setNewDL] = useState(null)
    const [mainArr, setMainArr] = useState(data)

    useEffect(()=> {
        if(typeof(mainArr) === 'object') {
        let arr = [...mainArr]

        arr.forEach(s=> {
            s.isUpdating = false
        })

        setMainArr(arr)
        }
        
    }, [data])



    const currtDate = new Date()
    
    const updateDL = async (_cn, _i) => {
            if(newDL !== '' && newDL !== null) {

                let arr0 = [...cls[_index].data.archive]
                

                let pr = arr0[_i].price
                let lpn = arr0[_i].lps.length + 1

                arr0[_i].dueLimit = newDL
                arr0[_i].lps.push({
                  lp: newDL,
                  price: pr,
                  n: lpn
                })


                
                const userRef = doc(db, 'users', _cn)
    
                await updateDoc(userRef, {archive: arr0})
    
    
                let arr = [...cls]
    
                arr[_index].isUpdating = false
    
                setCls(arr)
                setNewDL('')
            }
        }

        
        const updating = (_i) => {
            let arr = [...mainArr]
    
            arr.forEach((c, i)=> {
                if(_i !== i && c.isUpdating) {
                    arr[i].isUpdating = false
                }
            })
    
            arr[_i].isUpdating = true
    
            setMainArr(arr)
            setNewDL('')
        }

        useEffect(()=> {
            if(typeof(mainArr) == 'object') {
            let arr = [...mainArr]

            arr.forEach((s)=> {
                s.isUpdating = false
            })

            setMainArr(arr)
            }
        }, [cls])

    const ren = cls[_index].data.archive?.map((s, _i)=> {
        const lpObj = s.lps?.reduce((max, curr)=> {
          return (max.n || 0) < (curr.n || 0) ? curr : max
        }, {})

        const dls = lpObj?.lp;
        // const dlComponents = dls?.split(' ');
        // const day = parseInt(dlComponents[0], 10);
        // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        // const month = monthNames.indexOf(dlComponents[1]);
        // const year = parseInt(dlComponents[2], 10);
        // const dl = new Date(year, month, day);

        const DateX = new Date(dls)
        const td = new Date(new Date().toUTCString())

        const nxtD = new Date(DateX)
        nxtD.setMonth(nxtD.getMonth() + 1)

        // const tDiff = dl - currtDate;

        const tdf = nxtD - td

        const rem = Math.ceil(tdf / (1000 * 60 * 60 * 24));
       

            return (<div className='cdl__cont' key={_i}>
               <p className='cdl__name_'>{s.service?.replace(/seo/i, 'SEO')}</p>
               <p className='cdl__startDate'>{s.startDate}</p>
                 <p className='cdl__limit'>{dls}</p>
               <p className={`cdl__rem ${rem >= 20 ? 'cdl__green': (rem <20 && rem>= 15)? 'cdl__yellow': 'cdl__red'}`}>{rem}</p>
               <p className={`cdl__nxt_p`}>{nxtD.toLocaleDateString('en', {day: 'numeric', month: 'short', year: 'numeric'})}</p>
                <p className={`cdl__status_ ${s.status !== 'active'? 'cdl__status_inactive' : ''}`}>{s.status}</p>

                {!mainArr[_i]?.isUpdating && <div style={{width: '200px', display: 'flex', paddingLeft: '10px', paddingTop: '5px', marginLeft: 'auto', justifyContent: 'left', alignItems: 'flex-start', backgroundColor: 'rgba(255, 208, 0, 0.125)'}}>{s?.status === 'active' && <button className='cdl__btn0' onClick={()=> updating(_i)}>Update</button>}</div>}
                 {mainArr[_i]?.isUpdating && <div className='cdl__update_cont'>
                     <input type='text' className='cdl__input' required value={newDL} onChange={event=> setNewDL(event.target.value)}/>
                    <button className='cdl__btn' onClick={()=> updateDL(cls[_index].cn, _i)}>Update Limit</button>
               </div>}
            </div>)
    })

  ////

  return (
    <div className="accordion-container">
      
        <div className="accordion-section">
          <div
            className={`accordion-header ${openSection === _index ? 'open active' : ''}`}
            onClick={() => toggleSection(_index)}
          >
            <span className='cdl__name'>{cls[_index].data.name}</span>
          </div>
          {openSection === _index && (<>
            <div className='cdl__cont_0'>
          <div className='cdl__cont' style={{marginBottom: '0px', paddingTop: '20px', color: 'gray'}}>
            <p className='cdl__name' style={{color: 'gray'}}>Service</p>
            <p className='cdl__startDate' style={{color: 'gray'}}>Start Date</p>
            <p className='cdl__limit'>Last Payment</p>
            <p className='cdl__rem'>Remaining</p>
            <p className='cdl__nxt_p'>Next Payment</p>
            <p className='cdl__status'>Status</p>
            <p className='cdl__update_title'>Update Due Limit</p>
          </div>
          <div className="accordion-content_">{ren}</div></div>
            
            </>
          )}
        </div>
      
    </div>
  );
};

export default Accordion;