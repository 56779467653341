import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Communications.css'
import './Progress.css'
import Header from './Header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from '../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { clcsnActions, csnActions } from '../store'
import Loading from './Loading'


function Communications(props) {

    const rooms = useSelector(state => state.acd)
    const ud = useSelector(state => state.userData)
    
    const [cPosition, setCPosition] = useState({x: 0, y: 0})

    let erm
    let crm

    const handleOnMouseMove = e => {
        const { clientX, clientY, currentTarget } = e
        const rect = currentTarget.getBoundingClientRect()

        setCPosition({
            x: clientX - rect.left,
            y: clientY - rect.top
        })
    }

    
    if(ud && ud.userData?.role !== 'client') {
        erm = rooms.acd?.map(room => {
                           return (
                            <Link to={`/communications/room?part=youAnd${room.name}`} style={{textDecoration: 'none'}}><div className='comm-box' key={room.id} onMouseMove={e=> handleOnMouseMove(e)} style={{'--mouse-x': `${cPosition.x}px`, '--mouse-y': `${cPosition.y}px`}}>
                                <h4 style={{textDecoration: 'none', zIndex: '4'}}>{room.name}<span style={{color: 'rgb(170, 170, 170)', marginLeft: '50px'}}>{Array(20).fill('\u00a0')}{room?.services.join(' - ').replace(/seo/i, 'SEO')}</span></h4>{Array(10).fill('\u00a0')}{props.notes?.filter(x=> x.name.toLowerCase() == room.name).length !== 0 && <span style={{marginLeft: '10px', color: 'white', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: 'rgb(224, 47, 74)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '5'}}>{props.notes?.filter((x)=> x.name.toLowerCase() === room.name, 0).length}</span>}
                            </div>
                            <hr className='dv'/>
                            </Link>
                            )
                            }
                        )
    }

    if(ud && ud.userData?.role == 'client') {
        crm = ud.userData.assignedMembers?.map(room => {
            return (
                <>
                {<Link to={`/communications/room?part=youAnd${room.name}`} style={{textDecoration: 'none'}}><div className='comm-box' key={room.id} onMouseMove={e=> handleOnMouseMove(e)} style={{'--mouse-x': `${cPosition.x}px`, '--mouse-y': `${cPosition.y}px`}}>
                    <h4 style={room.canceled ? {fontStyle: 'italic', color: 'gray'}: {}}>{room.name}</h4>{props.notes?.filter(x=> x.name.toLowerCase() == room.name.toLowerCase()).length !== 0 && <span style={{marginLeft: '10px', color: 'white', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: 'rgb(224, 47, 74)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{props.notes?.filter((x)=> x.name.toLowerCase() === room.name.toLowerCase(), 0).length}</span>}
                </div>
                <hr className='dv'/>
                </Link>}
                </>
            )
        })
    }


    if(auth.currentUser) {
            return (
            <div className='communications-cont'>
                {ud?.userData?.role !== 'client'? <><h2 id='serviceProgress__h2'>Communication Rooms</h2>{erm}</> : <><h2 id='serviceProgress__h2'>Communicate with your Specialist</h2>{crm}</>}
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default Communications