import React, { useEffect, useRef, useState } from 'react'
import './DisplayAllMessages.css'
import { collection, doc, getDocs } from 'firebase/firestore'
import { db } from '../firebase'

function DisplayAllMessages() {
    const [allMessages, setAllMessages] = useState([])
    const [names, setNames] = useState([])
    const scrollDown = useRef(null)

    const rcn = window.location.pathname.split('/').pop()

    useEffect(()=> {
        const roomRef = collection(db, 'rooms', rcn, 'messages')

        const getAllMessages = async () => {
            const data = await getDocs(roomRef)
            let arr = []

            data.forEach(m=> {
                arr.push(m.data())
            })

            

            arr.filter((item, index)=> {
                return arr.indexOf(item) == index
            })

            setAllMessages(arr)
        }

        if(allMessages.length === 0) getAllMessages()
    }, [])


    if(allMessages.length !== 0) {
        allMessages.sort(function(x, y) {
            return x.n - y.n
        })
    }

    const parts = new Set()

    allMessages.forEach(m=> {
        parts.add(m.name)
    })

    let partArr = Array.from(parts)

    let partsNames = partArr.filter(n=> n !== undefined)

    partsNames.filter((item, index) => {
        return partsNames.indexOf(item) === index
    })


    let ren = allMessages?.map(m=> {
        return (
            <div className={`displayAllMessages__message_cont ${m.role == 'client'? 'displayAllMessages__cl': 'displayAllMessages__spec'}`}>
                <p className='displayAllMessages__name'>{m.name} - {m.date}</p>
                <p className='displayAllMessages__msg'>{m.body}</p>
            </div>
        )
    })

    useEffect(()=> {
        scrollDown?.current.scrollIntoView({behavior: 'smooth'})
    }, [allMessages])


  return (
    <div className='displayAllMessages__cont'>
        <h2 className='displayAllMessages__h2'>{partsNames.join(' | ')}</h2>
        <div className='displayAllMessages__inner_cont'>
            {ren}

            <div ref={scrollDown}></div>
        </div>
    </div>
  )
}

export default DisplayAllMessages