import React, { useEffect, useState } from 'react'
import './Sidebar.css'
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ForumIcon from '@mui/icons-material/Forum';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FolderIcon from '@mui/icons-material/Folder';
import BugReportIcon from '@mui/icons-material/BugReport';
import GroupsIcon from '@mui/icons-material/Groups';
import ListIcon from '@mui/icons-material/List';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import HubIcon from '@mui/icons-material/Hub'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Sidebar(props) {

    const [checkPath, setCheckPath] = useState(0)

    const cAuth = useSelector(state=> state.auth.authState)
    const ud = useSelector(state=> state.userData.userData)

    let url = window.location.pathname
    // let currentRoute = url.replace(/[^a-zA-Z0-9]/g, '').trim()
    let currentRoute = url.split('/').pop()

    let linkStyle = {
        textDecoration: 'none'
    }


    
    useEffect(()=> {
        url = window.location.pathname
        // currentRoute = url.replace(/[^a-zA-Z0-9]/g, '').trim()
        currentRoute = url.split('/').pop()
    }, [checkPath])

    if(cAuth) {
  return (
    <div className='sidebar__cont'>
        {ud?.role !== 'manager' && <Link to='/progress' style={linkStyle}><div className='sidebar__options' style={{marginTop: '30px', backgroundColor: currentRoute=='progress'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <ShowChartIcon className='sidebar__icons'/>
            <p>Progress</p>
        </div></Link>}

        {ud?.role !== 'manager' && <Link to='/communications' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='communications'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <ForumIcon className='sidebar__icons' />
            <p>Communications {props?.notes.length > 0? <span style={{width: '30px', height: '25px', borderRadius: '2px', backgroundColor: 'rgb(224, 47, 74)', paddingInline: '5px', marginLeft: '5px'}}>{props?.notes.length}</span>: ''}</p>
        </div></Link>}
        {ud?.role !== 'manager' && <Link to='/reports' style={linkStyle}>
        <div className='sidebar__options' style={{backgroundColor: currentRoute=='reports'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <AssessmentIcon className='sidebar__icons' />
            <p>Reports</p>
        </div></Link>}
        {ud?.role == 'client' && <Link to='/archive' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='archive'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <FolderIcon className='sidebar__icons' />
            <p>Archive</p>
        </div></Link>}
        {ud?.role == 'client' && <Link to='/subscription-settings' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='subscription-settings'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <SettingsIcon className='sidebar__icons' />
            <p>Subscription Settings</p>
        </div></Link>}
        {ud?.role == 'manager' && <Link to='/add-user' style={linkStyle}><div className='sidebar__options' style={{marginTop: '30px', backgroundColor: currentRoute=='add-user'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <PersonAddIcon className='sidebar__icons' />
            <p>Add User</p>
        </div></Link>}
        {ud?.role == 'manager' && <Link to='/update-user' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='update-user'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <ManageAccountsIcon className='sidebar__icons' />
            <p>Update User</p>
        </div></Link>}
        {ud?.role == 'manager' && <Link to='/all-communications' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='all-communications'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <ForumIcon className='sidebar__icons' />
            <p>All Communications</p>
        </div></Link>}
        {ud?.role == 'manager' && <Link to='/clients-due-limits' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='clients-due-limits'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <ListIcon className='sidebar__icons' />
            <p>Clients Due Limits</p>
        </div></Link>}
        {ud?.role == 'manager' && <Link to='/employee-center' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='employee-center'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <HubIcon className='sidebar__icons' />
            <p>Employee Control Center</p>
        </div></Link>}
        <Link to='/report-bug' style={linkStyle}><div className='sidebar__options' style={{backgroundColor: currentRoute=='report-bug'? 'rgba(4, 4, 83)':''}} onClick={()=> setCheckPath(prev => prev + 1)}>
            <BugReportIcon className='sidebar__icons' />
            <p>{ud?.role !== 'manager' ? 'Report A Bug': 'Bug Reports'}</p>
        </div></Link>
    </div>
  ) }
}

export default Sidebar