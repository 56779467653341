import React, { useEffect, useState } from 'react'
import './UpdateUser.css'
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase'

function UpdateUser() {
    const [cls, setCls] = useState(null)
    const [selectedCl, setSelectedCl] = useState('')
    const [selectedField, setSelectedField] = useState('')
    const [name, setName] = useState('')
    const [clName, setClName] = useState('')
    const [addServ, setAddServ] = useState('')
    const [addServPri, setAddServPri] = useState('')
    const [addServDue, setAddServDue] = useState('')
    const [addServSt, setAddServSt] = useState('')
    const [addServEn, setAddServEn] = useState('')
    const [addServStat, setAddServStat] = useState('')
    const [addServSpec, setAddServSpec] = useState('')
    const [addServSpecCN, setAddServSpecCN] = useState('')
    const [addServRoomID, setAddServRoomID] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('')
    const [speciServ, setSpeciServ] = useState('')
    const [roomMod, setRoomMod] = useState('')
    const [index, setIndex] = useState(null)
    const [updated, setUpdated] = useState(false)
    

    useEffect(()=> {
        const csRef = collection(db, 'users')
        const q = query(csRef, where('role', '==', 'client'))

        const getCs = async () => {
            const data = await getDocs(q)

            let arr = []

            data.forEach(d=> {
                arr.push(d.data())
            })
            setCls(arr)
        }

        getCs()
    }, [])




    useEffect(()=> {
        if(index && cls && selectedCl) {
            setClName(cls[index].name)
        }
    }, [selectedCl])

    
    const handleIndex = (_i) => {
            setIndex(_i)
        }

        let options = cls?.map((cl, i)=> {
        return (
            <option className='updateUser__opt' key={i} value={cl.cn}>{cl.name}</option>
        )
    })

    let sOptions 
    
    if(index && typeof(index) == 'number' && index >= 0) {
        sOptions = cls[index]?.archive.map((s, i)=> {
        return (
            <option className='updateUser__opt' key={i} value={s.service}>{s.service}</option>
        )
        })
    }


    useEffect(()=> {
        let clearCheckMark
        if(updated) {
            clearCheckMark = setTimeout(()=> {
                setUpdated(false)
            }, 4000)
        }

        return () => {
                if(clearCheckMark) {
                clearTimeout(clearCheckMark)
            }
        }
    }, [updated])



    const handleUpdate = async () => {
        const clRef = doc(db, 'users', selectedCl)


        // const field = selectedField == 'name'? 'name': selectedField.toLowerCase().split(' ')[0] == 'arch' ? 'archive' : 'add'

        let field
        
        if(selectedField == 'name') {
            field = 'name'
        } else if(selectedField.toLowerCase().split(' ')[0] === 'arch') {
            const secStr = selectedField.toLowerCase().split(' ')[1]
            if(secStr === 'status') field = 'archive'
            if(secStr === 'end-date') field = 'archive'
            if(secStr === 'add-service') field = 'add-service'
        }


        let part

        if(field === 'name' && (name !== '' || name !== null)) {
            part = field

            updateDoc(clRef, {field: name})
        }

        if(field === 'archive' && (status !== '' || endDate !== '')) {
            const property = selectedField.toLowerCase().split(' ')[1]


            if(property === 'end-date') {
                let str0 = property.split('-')[0]
                let str1 = property.split('-')[1]
                let str2 = str1.charAt(0).toUpperCase() + str1.slice(1)

                part = str0+str2

            } else {
                part = property
            }

            let clObj

            cls.map(c=> {
                if(selectedCl === c.cn) {
                    clObj = c
                }
            })

            let spec = clObj.archive.filter(arch=> {
                if(arch.service === speciServ && arch.status === 'active') {
                    return arch.specialist
                }
            })


            let _i = clObj.archive.findIndex(i=> i.service == speciServ && i.status === 'active')

            clObj.archive[_i][part] = part == 'status'? status : endDate


            
            if(part === 'status') {
                clObj.assignedMembers.map(m=> {
                    if(m.name === spec[0].specialist && !m.canceled)
                    m.canceled = true
                })
            }

            let sCN 
            let cCN = clObj.cn

            clObj.assignedMembers.map(y => {
                if(y.name === spec[0].specialist) {
                    sCN = y.cn
                }
            })

            const rid = cCN+sCN


            const rRef = doc(db, 'rooms', rid)

            const progRef = doc(db, 'progress', cCN)

            const progData = (await getDoc(progRef)).data()

            let progDatax = progData.progress
            
            let progressArr = []
            
            progDatax.map(p=> {
                if(p.service === speciServ && p.status === 'active') {
                    p.status = 'inactive'
                    progressArr.push(p)
                } else {
                    progressArr.push(p)
                }
            })


            
            updateDoc(rRef, {canceled: true})
            updateDoc(progRef, {progress: progressArr})
            updateDoc(clRef, {archive: clObj.archive})
            updateDoc(clRef, {assignedMembers: clObj.assignedMembers})
        }



        if(field == 'add-service' && addServ !== '' && addServPri !== '' && addServSt !== '' && addServEn !== '' && addServDue !== '' && addServStat !== '' && addServSpec !== '' && addServSpecCN !== '') {
            let clObj = cls[index]

            clObj.archive.push({
                service: addServ,
                price: addServPri,
                startDate: addServSt,
                endDate: addServEn,
                dueLimit: addServDue,
                status: addServStat,
                specialist: addServSpec,
                lps: [
                    {
                        n: 1,
                        price: addServPri,
                        lp: addServDue
                    }
                ]
            })

            

            if(!clObj.services.includes(addServ)) {clObj.services.push(addServ)}

            
            const progRef = doc(db, 'progress', selectedCl)

            let progObj = (await getDoc(progRef)).data()

            const progr = {
                    assignedSpecialist: addServSpec,
                    name: clName,
                    progress: [],
                    service: addServ,
                    startDate: addServSt,
                    status: 'active'
                }

            progObj.progress.push(progr)
            

            
            let assignedMembersIndex = clObj.assignedMembers.findIndex(i=> i.cn === addServSpecCN)

            if(Number(assignedMembersIndex) === -1) {
                clObj.assignedMembers.push({
                name: addServSpec,
                cn: addServSpecCN
                })
            }



            const specRef = doc(db, 'users', addServSpecCN)

            let specObj = (await getDoc(specRef)).data()

            let assignedClArr = specObj.assignedClients

            const assignedClIndex = specObj.assignedClients.findIndex(i => i.cn === selectedCl)

            let rCheckExis = false
                        let rCheckServ = false

                        specObj.assignedClients.map(cl => {
                            if(cl.name.toLowerCase() === clName.toLowerCase()) {
                                rCheckExis = true
                            }

                            if(cl.services.includes(addServ)) {
                                rCheckServ = true
                            }


            if(Number(assignedClIndex) !== -1) {
                let assignedClientServices = specObj.assignedClients[assignedClIndex].services
                if(!assignedClientServices.includes(addServ.toLowerCase())) {assignedClientServices.push(addServ)}
                assignedClArr[assignedClIndex].services = assignedClientServices
            } else {
                assignedClArr.push({
                    name: clName,
                    cn: selectedCl,
                    services: addServ.split(' ')
                })
            }


            if(specObj.lastReports.hasOwnProperty(clName)) {
                specObj.lastReports[clName] = {}
                specObj.lastReports[clName][addServ] = ''
            } else {
                specObj.lastReports[clName] = {}
                specObj.lastReports[clName][addServ] = ''
            }
                
    

            updateDoc(clRef, {archive: clObj.archive})
            updateDoc(clRef, {assignedMembers: clObj.assignedMembers})
            updateDoc(clRef, {services: clObj.services})

            updateDoc(specRef, {assignedClients: specObj.assignedClients})
            updateDoc(specRef, {lastReports: specObj.lastReports})

            updateDoc(progRef, {progress: progObj.progress})
            })



            if(!rCheckExis) {
                const roomCN = selectedCl+addServSpecCN
                const roomsRef = collection(db, 'rooms', roomCN, 'messages')

                const d = `${new Date().getDate()} ${new Date().toLocaleString('en', {month: 'long'})} ${new Date().getFullYear()}`

                await addDoc(roomsRef, {
                    n: 1,
                    date: d,
                    body: `This room was created on ${d}`,
                    role: 'est'
                })

                const roomsDocRef = doc(db, 'rooms', roomCN)

                await setDoc(roomsDocRef, {roomCreatedOn: d})
            }
            
        }


        setAddServ('')
        setAddServDue('')
        setAddServEn('')
        setAddServPri('')
        setAddServSpec('')
        setAddServSpecCN('')
        setAddServSt('')
        setAddServStat('')
        setClName('')
        setEndDate('')
        setCls(null)
        setName('')
        setSelectedCl('')
        setSpeciServ('')
        setSelectedField('')
        setIndex(null)
        setStatus('')
        setAddServRoomID('')


        setUpdated(true)
    }



    
  return (
    <div className='updateUser__cont'>
        <h2 className='updateUser__h2'>Update User Field</h2>

        {!updated && <div><div className='updateUser__select_cont'>
        <select className='updateUser__select' name='clients' onChange={e=> {setSelectedCl(e.target.value); handleIndex(Number(e.target.selectedIndex) - 1);}}>
            <option className='updateUser__opt' value=''>Select client</option>
            {options}
        </select>
        

        <select className='updateUser__select' name='filed' onChange={e=> setSelectedField(e.target.value)}>
            <option className='updateUser__opt' value=''>Select field</option>
            <option className='updateUser__opt' value='name'>Name</option>
            <optgroup label='Archive'>
                <option className='updateUser__opt' value='arch add-service'>Add service</option>
                <option className='updateUser__opt' value='arch status'>Status</option>
                <option className='updateUser__opt' value='arch end-Date'>End date</option>
            </optgroup>
        </select>


        {(selectedField?.split(' ')[1] == 'status' || selectedField?.split(' ')[1] == 'end-Date') && <select className='updateUser__select' name='services' onChange={e=> setSpeciServ(e.target.value)}>
            <option className='updateUser__opt' value=''>Select Service</option>
            {sOptions}
        </select>}

    
        </div>

        <div className='updateUser__fields_cont'>
            {selectedField?.toLowerCase() === 'name' && <div>
                <span>Name</span>
                <input type='text' required value={name} onChange={e=> setName(e.target.value)}/>
            </div>}

            {selectedField?.toLowerCase().split(' ')[0] === 'arch' && <div className='updateUser__fields_cont'>

                {selectedField?.toLowerCase().split(' ')[1] === 'add-service' && <div>
                <span>Add Service</span>

                <div  style={{display: 'flex', flexDirection: 'row', marginTop: '50px', gap: '170px'}}>

                <div>
                <label>Service:</label>
                <input type='text' required value={addServ} onChange={e=> setAddServ(e.target.value)}/>

                <label>Price:</label>
                <input type='text' required value={addServPri} onChange={e=> setAddServPri(e.target.value)}/>

                <label>Last Payment:</label>
                <input type='text' required value={addServDue} onChange={e=> setAddServDue(e.target.value)}/>
                </div>

                <div>
                <label>Start Date:</label>
                <input type='text' required value={addServSt} onChange={e=> setAddServSt(e.target.value)}/>

                <label>End Date:</label>
                <input type='text' required value={addServEn} onChange={e=> setAddServEn(e.target.value)}/>
                
                <label>Status:</label>
                <input type='text' required value={addServStat} onChange={e=> setAddServStat(e.target.value)}/>
                </div>

                <div>
                <label>Specialist:</label>
                <input type='text' required value={addServSpec} onChange={e=> setAddServSpec(e.target.value)}/>

                <label>Specialist's CN:</label>
                <input type='text' required value={addServSpecCN} onChange={e=> setAddServSpecCN(e.target.value)}/>
                
                <label>Room ID:</label>
                <input type='text' required value={selectedCl+addServSpecCN} disabled/>
                </div>

                </div>

                </div>}

                {selectedField?.toLowerCase().split(' ')[1] === 'status' && <div>
                <span>Status</span>
                <input type='text' required value={status} onChange={e=> setStatus(e.target.value)}/>
                </div>}

                {selectedField?.toLowerCase().split(' ')[1] === 'end-date' && <div>
                <span>End date</span>
                <input type='text' required value={endDate} onChange={e=> setEndDate(e.target.value)}/>
                </div>}

            </div>}

            <button className='updateUser__btn' onClick={handleUpdate}>Update</button>
        </div></div>}

        {updated && <div class="check-circle_2">
            <div class="check-circle__mark_2"></div>
        </div>}

        
        
    </div>
  )
}

export default UpdateUser