import React, { useState } from 'react'
import './ProgressCard.css'
import { useSelector } from 'react-redux'

function ProgressCard(props) {
  const [cPosition, setCPosition] = useState({x: 0, y: 0})
  const ud = useSelector(state=> state.userData.userData)
  let cs
  let cs2

  if(ud?.role !== 'client') {
    cs = ud?.assignedClients.filter(x => x.name.toLowerCase() == props.progress.name.toLowerCase())[0].services.join(' - ')
  }


  
  const handleOnMouseMove = e => {
    const { clientX, clientY, currentTarget } = e

    const rect = currentTarget.getBoundingClientRect()

    setCPosition({x: clientX - rect.left, y: clientY - rect.top})
  }


  return (
    <>
    {props?.status === 'active' && <div className='progressCard__box' id='progressCard__box' onMouseMove={e=> handleOnMouseMove(e)} style={{'--mouse-x': `${cPosition.x}px`, '--mouse-y': `${cPosition.y}px`}}>
        <h3 id='progressCard__serviceName'>{props.progress.service?.replace(/seo/i, 'SEO')}</h3>
        <div style={{marginTop: 'auto', display: 'flex', flexDirection: 'column'}}>
        <p id='progressCard__para'>{ud.role == 'client'? <span style={{color: 'darkslategray'}}>Name: <span style={{color: 'black'}}>{props.progress.name}</span></span>: <span style={{color: 'darkslategray'}}>Client' Name: <span style={{color: 'black'}}>{props.progress.name}</span></span>}</p>
        <p id='progressCard__para'>{ud?.role == 'client'? <span style={{color: 'darkslategray'}}>Assigned Specialist: <span style={{color: 'black'}}>{props.progress.assignedSpecialist}</span></span>: <span style={{color: 'darkslategray'}}>Services: <span style={{color: 'black'}}>{cs.replace(/seo/i, 'SEO')}</span></span>}</p>
        <p id='progressCard__para'>{ud.role == 'client'? <span style={{color: 'darkslategray'}}>Start Date: <span style={{color: 'black'}}>{props.progress.startDate}</span></span>: ''}</p>
        </div>
    </div>}
    </>
  )
}

export default ProgressCard