import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";


const acdSlice = createSlice({
    name: 'acd',
    initialState: {acd : null},
    reducers: {
        setACD(state, action) {state.acd = action.payload}
    }
})

const userSlice = createSlice({
    name: 'userData',
    initialState: {userData: null},
    reducers: {
        setUserData(state, action) {state.userData = action.payload}
    }
})


const progressSlice = createSlice({
    name: 'progressData',
    initialState: {progressData: null},
    reducers: {
        setProgressData(state, action) {state.progressData = action.payload}
    }
})


const csnSlice = createSlice({
    name: 'ACD',
    initialState: {csn: 'xy'},
    reducers: {
        setCSN(state, action) {state.csn = action.payload}
    }
})

const authSlice = createSlice({
    name: 'auth',
    initialState: {authState: false},
    reducers: {
        setAuthState(state, action) {state.authState = true}
    }
})

const clcsnSlice = createSlice({
    name: 'clcsn',
    initialState: {clcsn: 'xy'},
    reducers: {
        setCLCSN(state, action) {state.clcsn = action.payload}
    }
})



const store = configureStore({
    reducer: {
        csn: csnSlice.reducer,
        auth: authSlice.reducer,
        clcsn: clcsnSlice.reducer,
        userData: userSlice.reducer,
        progressData: progressSlice.reducer,
        acd: acdSlice.reducer
    }
})



export const csnActions = csnSlice.actions
export const authStateActions = authSlice.actions
export const clcsnActions = clcsnSlice.actions
export const userDataActions = userSlice.actions
export const progressDataActions = progressSlice.actions
export const acdActions = acdSlice.actions
export default store