import React, { useEffect, useState } from 'react'
import './Progress.css'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'



function ServiceProgress(props) {
    const [prog, setProg] = useState(null)
    const progress = useSelector(state => state.progressData.progressData?.progress)
    const ud = useSelector(state => state.userData.userData)

    const regex = /^[a-z]+/ig

    let service = window.location.pathname.split('/').pop().replaceAll('%20', ' ').toLowerCase()
    let arr
    let _i = ud?.archive?.findIndex(s=> s.service == service && s.status === 'active')
  
    let actvCheck
    if(ud?.role == 'client') {
        actvCheck = ud?.archive[_i].status
    }

    progress?.map(p => {
        if(p.service.split(' ').shift().toLowerCase() == service) {
            arr = p.progress
        }
    })


    
    useEffect(()=> {
        if(arr) setProg(arr)
    }, [arr])

    const ren = prog?.map(p=> {
        if(p.trim() !== '') {
        return(
        <div className='progress__box'>
            <div className='pulse circle'></div>
            <p>{p}</p>
        </div>)
        
        }
    })


    let renS

    if(ud?.role !== 'client') {
        let arr2 = props.cProg?.filter(x => x.name.toLowerCase().trim() == service.trim())[0]
        let arr3 = arr2?.prog.progress.filter(x=> x.assignedSpecialist.toLowerCase() == ud?.name.toLowerCase())
        renS = arr3?.map(s => {
            if(s) {return (
                <Link to={`/progress/${service}/${s?.service}`} style={{textDecoration: 'none'}}>
                    <h4 className='serviceProgress_h4'>{s?.name} - {s.service?.replace(/seo/i, 'SEO')}</h4>
                </Link>
            )}
        })
    }

    
  return (
    <div style={{overflowX: 'hidden'}}>
    {ud?.role !== 'client' && <a href='/progress' style={{textDecoration: 'none', display: 'block'}}><p className='breadcrumbs'>{'<< Back'}</p></a>}
    {(ud?.role == 'client' && prog?.length !== 0 && actvCheck == 'active')? <div style={{display: 'flex', flexDirection: 'column', width: '1000px'}}><h2 id='serviceProgress__h2'>Real-Time Current Progress Updates</h2>{ren}</div> : (ud?.role == 'client' && actvCheck == 'active' && prog?.length == 0)? 
    <div className='progress__box'>  
            <p>The specialist has not yet provided real-time progress updates on the service</p>
        </div> : ud?.role == 'client' && actvCheck !== 'active' && <div className='progress__box'>
                
                     <p>The service is currently inactive</p>
                </div>}
    {ud?.role == 'specialist' && <div style={{width: '200px'}}><h2 id='serviceProgress__h2'>Select Client</h2><p>{renS}</p></div>}
    </div>
  )
}

export default ServiceProgress