import React from 'react'
import './Messages.css'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { db } from '../firebase'
import { collection, getDocs, getDoc, query, where, addDoc, doc, updateDoc, onSnapshot, Timestamp } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { clcsnActions, csnActions } from '../store'
import Loading from './Loading'



function Messages(props) {

    const [messages, setMessages] = useState(null)


    const scrollDown = useRef(null)
    const fieldRef = useRef(null)
    const contRef = useRef(null)

    
    const [status, setStatus] = useState(false)
    

    let part = new URLSearchParams(window.location.search).get('part')
    const regex = /(?<=\And).*/
    const partName = part.match(regex)[0].trim()
    
    const ud = useSelector(state => state.userData.userData)

    let ciduid

    if(ud?.role !== 'client') {
        for(let i = 0; i < ud?.assignedClients.length; i++) {
            if (ud?.assignedClients[i].name === partName) {
                ciduid = ud?.assignedClients[i].cn + auth.currentUser?.uid
            }
        }
    } else if(ud?.role == 'client') {
        for(let j = 0; j < ud?.assignedMembers.length; j++) {
            if (ud?.assignedMembers[j].name == partName) {
                ciduid = auth.currentUser?.uid + ud?.assignedMembers[j].cn
                
            }
        }
    }

    useEffect(()=> {
        if(ciduid){const statRef = doc(db, 'rooms', ciduid)

        const getStat = async () => {
            let data = await getDoc(statRef)

            setStatus(data.data())
        }

        getStat()}
    }, [ciduid, ud])


    useEffect(()=> {
        let arr = props.notes?.filter(x => x.name.toLowerCase() == partName.toLowerCase())
        

        arr.map(async m => {
            const mRef = doc(db, 'rooms', ciduid, 'messages', m.id)

            await updateDoc(mRef, {seen: true})
        })
    }, [])
    


    useEffect(()=> {
        if(ud) {
            const messagesRef = collection(db, 'rooms', ciduid, 'messages')
            const q = query(messagesRef)
    
            onSnapshot(q, (snapshot) => {
                const msgs = []
    
                snapshot.docs.forEach((doc) => {
                    msgs.push({...doc.data(), id: doc.id})
                    })
    
                   setMessages(msgs)
                })
            }
    }, [auth.currentUser, ud, ciduid])



    if (messages?.length > 0) {
        messages.sort(function(a, b) {
            return a.n - b.n
        })
    }

    useEffect(()=> {
        props.ml()
    }, [])


    async function send() {
        const message = fieldRef.current.value.trim()
        fieldRef.current.value = ''
        const messagesRef = collection(db, 'rooms', ciduid, 'messages')
        let data = await getDocs(messagesRef)
        let len = data.docs.map(doc => ({...doc.data()})).length
        if(message !== '') {
            await addDoc(messagesRef, {
                name: ud.name,
                role: ud.role,
                body: message,
                date: new Date().toUTCString(),
                seen: false,
                uid: auth.currentUser.uid,
                n: len + 1
            })

            props.ml()
        }
    }

    let rm = messages?.filter(m=> m.role == 'est')[0]?.body?.match(/\bby\s+(.+)\b/)

    if(rm) {
        rm = rm[0]
    }


    // useEffect(() => {
    //     const unseenMessages = async () => {

    //         const q = query(messagesRef, where('seen', '==', false))
    //         const data = await getDocs(q)


    //         const d = data.docs.map(doc => ({...doc.data(), id: doc.id}))


    //         let arr = []
            
    //         d.map(m => {
    //             if (m.type === t && m.name === part) {arr.push(m)}
    //         })



    //         arr.map(async m => {
    //             const mRef = doc(db, 'users', sn.trim(), 'messages', m.id)
    //             await updateDoc(mRef, {seen: true})
    //         })

    //         setUnseen(arr)
    //     }

    //     unseenMessages()
    // }, [messages])


    useEffect(()=> {
        scrollDown.current?.scrollIntoView({behavior: 'smooth'})
    }, [messages])


    if(ud) {
        return (
                    <div className="main">
                        <div className='main__h5_cont'><div className='main__circle'></div><h5 className='main__h5'>This room is monitored {rm || ''}</h5></div>

                        <div className='cont' ref={contRef}>
                            {messages && messages?.map(msg => {
                                if(msg.role !== 'est') {
                                return (
                                    <div className={msg.uid == auth.currentUser?.uid? 'message-cont my-msgs' : 'message-cont other-msgs'}>
                                        <div className='msg-name-and-date' style={msg.role == 'client'? {color: 'rgb(168, 168, 168)'}: msg.role == 'specialist'? {color: 'rgba(155, 13, 13, 0.6)'}: {}}>
                                            <p style={{marginRight: '35px', marginLeft: '0px', textTransform: 'capitalize'}}>{msg.name} {msg.role !== 'client'? `|  ${msg.role}` : ''}</p>
                                            <p style={{marginLeft: 'auto', color: 'rgb(168, 168, 168)'}}>{msg.date}</p>
                                        </div>
                                        <p className='msg-body' style={msg.uid === auth.currentUser?.uid? {backgroundColor: 'rgb(131, 211, 255)'}: {backgroundColor: 'rgb(238, 238, 238)'}}>{msg.body}</p>
                                    </div>
                                )
                                } else if(msg.role == 'est') {
                                    return (
                                        <div className='msg__est'>{msg.body}</div>
                                    )
                                }
                            })}
                            
                            <div ref={scrollDown}></div>
                        </div>



                        {!status?.canceled && <div className="write">
                            <textarea rows="5" cols="60" name="message-field" id="message" ref={fieldRef}></textarea>
                            <button className="btn" onClick={send} >Send</button>
                        </div>}
                    </div>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default Messages