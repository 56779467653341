import React, { useState } from 'react'
import './Progress.css'
import { useSelector } from 'react-redux'
import { db } from '../firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore'

function SpecProgress(props) {
    const regex = /^[a-z]+/ig
    const part = window.location.pathname.split('/')
    const service = part.pop().replaceAll('%20', ' ').toLowerCase()
    const name = part.pop().replace('%20', ' ').toLowerCase()
    const ud = useSelector(state => state.userData.userData)
    let cn 
    ud?.assignedClients.map(c => {
        if(c.name.toLowerCase() == name) cn = c.cn
    })



    const [progressUpdate, setProgressUpdate] = useState(null)

    const cl = props?.cProg?.filter(x => x.name == name)[0]
    const progress = cl?.prog?.progress?.filter(y => y.service.toLowerCase() == service && y.assignedSpecialist.toLowerCase() === ud.name.toLowerCase())[0].progress
    
    let ren = progress?.map(p => {
        if(p.trim() !== '') {
        return (
            <>
                <p className='specProgress__p'>{p}</p>
            </>
        )
        }
    })


    
    async function updateProgress() {
        if(progressUpdate.trim() !== '' && cn) {
            const up = progressUpdate.trim().split(',')
            const progRef = doc(db, 'progress', cn)
            const data = await getDoc(progRef)
            const arrData = data.data().progress

            let _index = arrData.findIndex(s=> s.service.toLowerCase() === service && s.status === 'active')


            
            arrData[_index].progress = up

            await updateDoc(progRef, {progress: arrData})
            
            setProgressUpdate('')

            window.location.reload()
        }
    }

    
  return (
    <div style={{display: 'flex', width: '970px'}}>
        <div style={{overflowX: 'hidden', overflowY: 'scroll', paddingBottom: '20px'}}>
            {ud?.role !== 'client' && <a href={`/progress/${name}`} style={{textDecoration: 'none'}}><p className='breadcrumbs'>{'<< Back'}</p></a>}
            <h2 id='serviceProgress__h2'>Current Tasks</h2>
            {ren}
        </div>

        <div className='specProgress__right_div'>
            <h2 id='serviceProgress__h2'>Update Progress</h2>

            <div className='serviceProgress__progressUpdate_div'>
                <label style={{alignSelf: 'flex-start', color: 'rgba(255, 0, 0, 0.8)'}}>Use commas to separate tasks</label>
                <textarea rows='5' cols='20' placeholder='task1, task2, ...' onChange={event=> setProgressUpdate(event.target.value)} name='progress' id='serviceProgress__progressUpdate'></textarea>
                <button id='serviceProgress__btn' onClick={updateProgress}>Update</button>
            </div>
        </div>
    </div>
  )
}

export default SpecProgress