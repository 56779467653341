import React, { useEffect, useState } from 'react'
import './AddUser.css'
import { setDoc, addDoc, doc, collection } from 'firebase/firestore'
import { auth, db } from '../firebase'
import RefreshIcon from '@mui/icons-material/Refresh';
import { createUserWithEmailAndPassword } from 'firebase/auth';

function AddUser() {
    const [name, setName] = useState(null)
    const [role, setRole] = useState('')
    const [userCN, setUserCN] = useState(null)
    const [services, setServices] = useState(null)
    const [assignedName, setAssignedName] = useState(null)
    const [assignedCN, setAssignedCN] = useState(null)
    const [clientServices, setClientServices] = useState(null)
    const [roomCreatorName, setRoomCreatorName] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [dueLimit, setDueLimit] = useState(null)
    const [archServ, setArchServ] = useState(null)
    const [archEndDate, setArchEndDate] = useState(null)
    const [archPrice, setArchPrice] = useState(null)
    const [archStatus, setArchStatus] = useState(null)
    const [archLp, setArchLp] = useState(null)
    const [specAmount, setSpecAmount] = useState('')
    const [specLp, setSpecLp] = useState('')

    const [userEmail, setUserEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [userObj, setUserObj] = useState(null)
    const [isUserCreated, setIsUserCreated] = useState(false)

    const [userAdded, setUserAdded] = useState(false)



    function gsp(length = 12) {
        const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
        const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numberChars = '0123456789';
        const specialChars = '!@#$%^&*()_+{}[]|;:,.<>?';
      
        const allChars = lowercaseChars + uppercaseChars + numberChars + specialChars;
      
        let password = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * allChars.length);
          password += allChars.charAt(randomIndex);
        }
      
        return password;
      }

      useEffect(()=> {
        if(role !== '') {
            let password = gsp()

            setUserPassword(password)
        }
      }, [role])


    const createUser = (e) => {
        e.preventDefault()
        
        if(userEmail !== '' && userPassword !== '') {
            createUserWithEmailAndPassword(auth, userEmail, userPassword).then(r=> {
                setUserObj(r)
                setUserCN(r.user.uid)
            })

            setUserEmail('')
            setUserPassword('')
            setIsUserCreated(true)
        }
    }



    async function addUser() {
        let user
        
        if(role == 'client' && name && services && assignedName && assignedCN) {
            user = {
            name: name,
            role: role,
            services: services.split(', '),
            assignedMembers: [{name: assignedName, cn: assignedCN}],
            dueLimit: dueLimit,
            archive: [{
                service: archServ,
                price: archPrice,
                dueLimit: dueLimit,
                startDate: startDate,
                endDate: archEndDate,
                status: archStatus,
                specialist: assignedName,
                lps: [{
                    n: 1,
                    price: archPrice,
                    lp: archLp
                }]
            }],
            cn: userCN
            }

            const usersRef = doc(db, 'users', userCN)
            const progRef = doc(db, 'progress', userCN)

            await setDoc(progRef, {
                progress: [
                    {
                        name: name,
                        assignedSpecialist: assignedName,
                        progress: [],
                        service: services,
                        startDate: startDate,
                        status: 'active'
                    }
                ]
            })

            await setDoc(usersRef, user)

            setName(null)
            setAssignedCN(null)
            setAssignedName(null)
            setServices(null)
            setUserCN(null)
            setClientServices(null)
            setArchLp(null)

            setUserAdded(true)
        } else if(role !== 'client' && name && assignedName && assignedCN && clientServices) {
            user = {
                name: name,
                role: role,
                assignedClients: [{name: assignedName, cn: assignedCN, services: clientServices.split(', ')}],
                lastReports: {assignedName: ""},
                ps: [
                    {
                        n: 1,
                        amount: specAmount,
                        lp: specLp
                    }
                ]
            }

            user.lastReports[assignedName] = user.lastReports.assignedName
            delete user.lastReports.assignedName

            const usersRef = doc(db, 'users', userCN)

            const rcn = String(assignedCN + userCN)
            const roomsRef = collection(db, 'rooms', rcn, 'messages')
            const d = `${new Date().getDate()} ${new Date().toLocaleString('en', {month: 'long'})}  ${new Date().getFullYear()}`



            await addDoc(roomsRef, {
                n: 1,
                date: d,
                body: `This room was created on ${d} by ${roomCreatorName}`,
                role: 'est'
            })

            const roomsDocRef = doc(db, 'rooms', rcn)
            await setDoc(roomsDocRef, {roomCreatedOn: d})

            await setDoc(usersRef, user)

            setName(null)
            setAssignedCN(null)
            setAssignedName(null)
            setServices(null)
            setUserCN(null)
            setClientServices(null)

            setUserAdded(true)
        } else {
            alert('No empty fields!')
        }
    }

  return (
    <div style={{overflowX: 'hidden', overflowY: 'scroll', width: '1200px'}}>
    {role !== '' && <div style={{display: 'flex', alignItems: 'center', width: '900px', marginBottom: 'auto'}}><h2 className='addUser__h2'>Add User</h2> <RefreshIcon className='addUser__refresh' onClick={() => {setRole('');setUserAdded(false);setUserEmail(''); setUserPassword('');setIsUserCreated(false)}}/></div>}
    
    
    
    {role == '' && <div style={{display: 'flex', marginLeft: '30px', marginTop: '100px'}}>
        <div className='addUser__role_option addUser__client' data-value='client' onClick={event=> setRole(event.target.getAttribute('data-value'))}>Client</div>
        <div className='addUser__role_option addUser__specialist' data-value='specialist' onClick={event=> setRole(event.target.getAttribute('data-value'))}>Specialist</div>
        <div className='addUser__role_option addUser__assistant' data-value='assistant' onClick={event=> setRole(event.target.getAttribute('data-value'))}>Assistant</div>
    </div>}


    {!isUserCreated && role !== '' && <div className='addUser_create_user_cont'>
        <div>
            <label>Email</label>
            <input type='email' value={userEmail} onChange={e=> setUserEmail(e.target.value)} />
        </div>
        <div>
            <label>Password</label>
            <input type='text' disabled value={userPassword} />
        </div>

        <button onClick={(e) => createUser(e)}>Create User</button>
    </div>}

    

    {role !== '' && isUserCreated && <div style={{display: 'flex', flexDirection: 'column'}}>
        {!userAdded && <div className='addUser__input_cont'>
            <span>Name</span> <input type='text' value={name} required onChange={event=> setName(event.target.value)}/>
        </div>}

        {!userAdded && <div className='addUser__input_cont'>
            <span>Role</span> <input type='text' disabled required value={role} onChange={event=> setRole(event.target.value)}/>
        </div>}

        {!userAdded && <div className='addUser__input_cont'>
            <span>User CN</span> <input type='text' disabled required value={userObj?.user.uid} />
        </div>}

        {role == 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Services</span> <input type='text' required placeholder='service 1, service 2, etc...' onChange={event=> setServices(event.target.value)}/>
        </div>}

        {role == 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Assigned Members:</span> <span style={{marginLeft: '70px'}} className='addUser__sub'>Name</span><input required type='text' style={{width: '100px'}} onChange={event=> setAssignedName(event.target.value)}/> <span style={{marginLeft: '30px'}} className='addUser__sub'>CN</span> <input type='text' onChange={event=> setAssignedCN(event.target.value)}/>
        </div>}

        {role == 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Service Start Date</span> <input type='text' required onChange={event=> setStartDate(event.target.value)} />
        </div>}

        {role == 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Last Payment Date</span> <input type='text' required onChange={event=> setArchLp(event.target.value)} />
        </div>}

        {role == 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Due Limit</span> <input type='text' required onChange={event=> setDueLimit(event.target.value)} />
        </div>}

        {role !== 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Assigned Clients:</span> <span style={{marginLeft: '70px'}} className='addUser__sub'>Name</span><input required type='text' style={{width: '100px'}} onChange={event=> setAssignedName(event.target.value)}/> <span style={{marginLeft:'30px'}} className='addUser__sub'>CN</span> <input type='text' onChange={event=> setAssignedCN(event.target.value)}/> <span style={{marginLeft: '30px'}} className='addUser__sub'>Services</span> <input type='text' onChange={event=> setClientServices(event.target.value)}/>
        </div>}

        {role !== 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Payment:</span> <span style={{marginLeft: '70px'}} className='addUser__sub'>Amount</span><input required type='text' style={{width: '100px'}} value={specAmount} onChange={event=> setSpecAmount(event.target.value)}/> <span style={{marginLeft:'30px'}} className='addUser__sub'>Date</span> <input type='text' value={specLp} onChange={event=> setSpecLp(event.target.value)}/>
        </div>}

        {role !== 'client' && !userAdded && <div className='addUser__input_cont'>
            <span>Room Creator Name</span> <input type='text' required onChange={event=> setRoomCreatorName(event.target.value)} />
        </div>}

        {role === 'client' && !userAdded && <div className='addUser__input_cont'>
            <span style={{display: 'block'}}>Archive:</span>
            <div className='addUser__arch_cont'>
            <div><span className='addUser__sub'>Service</span> <input type='text' required onChange={event=> setArchServ(event.target.value)} placeholder=''/></div>
            <div><span className='addUser__sub'>End Date</span> <input type='text' required onChange={event=> setArchEndDate(event.target.value)} placeholder='ongoing'/></div>   
            <div><span className='addUser__sub'>Price</span> <input type='text' required onChange={event=> setArchPrice(event.target.value)} placeholder=''/></div>
            <div><span className='addUser__sub'>Status</span> <input type='text' required onChange={event=> setArchStatus(event.target.value)} placeholder='active'/></div>
            </div>
        </div>}

        {!userAdded && <button className='addUser__btn' onClick={addUser}>Add User</button>}

    </div>}


    
    {/* cp */}
        {userAdded && role !== '' && <div className="checkmark">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" xmlSpace="preserve">
        <path className="path" fill="none" stroke="#7fffd4" strokeMiterlimit="10" d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4 c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2.0,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2.0,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5 c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z" />
        <circle className="path" fill="none" stroke="#7fffd4" strokeWidth="4" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1" />
        <polyline className="path" fill="none" stroke="#7fffd4" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4" />
        <circle className="spin" fill="none" stroke="#7fffd4" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9" />
        </svg>
        <p className='checkmark__p'>User Added</p>
        </div>}
    
    </div>
  )
}

export default AddUser