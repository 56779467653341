import React, { useEffect, useState } from 'react'
import './DisplayBugReports.css'
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'

function DisplayBugReports() {
    const [reports, setReports] = useState(null)
    const [allReports, setAllReports] = useState(false)
    const [update, setUpdate] = useState(0)

    useEffect(()=> {
        const bugRepsRef = collection(db, 'bugs')

        const getBugReports = async () => {
            let arr = []
            const data = await getDocs(bugRepsRef)

            data.forEach(r=> {
                arr.push({data: r.data(), id: r.id})
            })


            setReports(arr)
        }

        getBugReports()
    }, [update])


    const solveBug = async (_id) => {
        const bugRepRef = doc(db, 'bugs', _id)

        await updateDoc(bugRepRef, {status: 'solved'})

        setUpdate(prev=> prev + 1)
    }


  return (
    <div className='dbr_cont'>
        <h2 className='dbr_h2'>Bug Reports <span style={{marginLeft: 'auto', cursor: 'pointer', fontSize: '17px'}} onClick={()=> setAllReports(prev=> !prev)}>{allReports? 'Display Unsolved Only' : 'Display All Bugs'}</span></h2>

        {!allReports && reports?.map(r=> {
            return (
                <>
                {r.data.status !== 'solved' && <div className='dbr_reports_box'>
                    <p className='dbr_report_p'>{r.data.reporter}</p>
                    <p className='dbr_report'>{r.data.report}</p>
                    <p className='dbr_report_p_date'>{r.data.reportedOn}</p>
                    <p className='dbr_report_status' style={r.data.status === 'unsolved'? {color: 'palevioletred'}: {color: 'rgb(0, 202, 0)'}} onClick={()=> solveBug(r.id)}>{r.data.status}</p>
                </div>}
                </>
            )
        })}

        {allReports && reports?.map(r=> {
            return (
                <>
                <div className='dbr_reports_box'>
                    <p className='dbr_report_p'>{r.data.reporter}</p>
                    <p className='dbr_report'>{r.data.report}</p>
                    <p className='dbr_report_p_date'>{r.data.reportedOn}</p>
                    <p className='dbr_report_status' style={r.data.status === 'unsolved'? {color: 'palevioletred'}: {color: 'rgb(0, 202, 0)'}} onClick={r.data.status == 'unsolved' ? ()=> solveBug(r.id): ()=> {}}>{r.data.status}</p>
                </div>
                </>
            )
        })}

        
    </div>
  )
}

export default DisplayBugReports