import React, { useEffect, useState } from 'react'
import './CDLDropdown.css'
import { db } from '../firebase';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';

function CDLDropdown({ data, isDropdownOpen, _index, cls, setCls, openSection }) {
    const [newDL, setNewDL] = useState(null)
    const [mainArr, setMainArr] = useState(data)

    useEffect(()=> {
        if(typeof(mainArr) === 'object') {
        let arr = [...mainArr]

        arr.forEach(s=> {
            s.isUpdating = false
        })

        setMainArr(arr)
        }
        
    }, [data])



    const currtDate = new Date()
    
    const updateDL = async (_cn) => {
            if(newDL !== '' && newDL !== null) {
                const userRef = doc(db, 'users', _cn)
    
                await updateDoc(userRef, {dueLimit: newDL})
    
    
                let arr = [...cls]
    
                arr[_index].isUpdating = false
    
                setCls(arr)
                setNewDL(null)
            }
        }

        
        const updating = (_i) => {
            let arr = [...mainArr]
    
            arr.forEach((c, i)=> {
                if(_i !== i && c.isUpdating) {
                    arr[i].isUpdating = false
                }
            })
    
            arr[_i].isUpdating = true
    
            setMainArr(arr)
            setNewDL('')
        }

        useEffect(()=> {
            if(typeof(mainArr) == 'object') {
            let arr = [...mainArr]

            arr.forEach((s)=> {
                s.isUpdating = false
            })

            setMainArr(arr)
            }
        }, [isDropdownOpen])

    const ren = cls[_index].data.archive?.map((s, _i)=> {
        const dls = s.dueLimit;
        const dlComponents = dls?.split(' ');
        const day = parseInt(dlComponents[0], 10);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames.indexOf(dlComponents[1]);
        const year = parseInt(dlComponents[2], 10);
        const dl = new Date(year, month, day);

        const tDiff = dl - currtDate;

        const rem = Math.ceil(tDiff / (1000 * 60 * 60 * 24));
        

            return (<div className='cdl__cont'>
               <p className='cdl__name'>{s.service}</p>
                 <p className='cdl__limit'>{s.dueLimit}</p>
               <p className={`cdl__rem ${rem >= 20 ? 'cdl__green': (rem <20 && rem>= 15)? 'cdl__yellow': 'cdl__red'}`}>{rem}</p>
                <p className='cdl__status'>{s.status}</p>

                {!mainArr[_i]?.isUpdating && <button className='cdl__btn0' onClick={()=> updating(_i)}>Update</button>}
                 {mainArr[_i]?.isUpdating && <div className='cdl__update_cont'>
                     <input type='text' className='cdl__input' required value={newDL} onChange={event=> setNewDL(event.target.value)}/>
                    <button className='cdl__btn' onClick={()=> updateDL(cls[_index].cn)}>Update Limit</button>
               </div>}
            </div>)
    })

        

    
    
    
    
        
    
    
        
    
        
        
  return (<>

    {openSection && (
        <div className="dropdown-content">
          <div className='cdl__cont' style={{marginBottom: '36px', color: 'gray'}}>
            <p className='cdl__name' style={{color: 'gray'}}>Service</p>
            <p className='cdl__limit'>Due Limit</p>
            <p className='cdl__rem'>Remaining</p>
            <p className='cdl__status'>Status</p>
            <p className='cdl__update_title'>Update Due Limit</p>
        </div>

           {ren}
        </div>
        )}
        
    
    </>
  )
}

export default CDLDropdown