import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import Unauthorized from './Unauthorized'

function RequireAuth({ _role, _comp}) {
    const ud = useSelector(state=> state.userData.userData)
    
    const LazyComponent = lazy(()=> import(`./${_comp}`))
    

  return (
    <Suspense fallback='<h1>Loading</h1>'>
        {ud?.role == _role? <LazyComponent /> : <Unauthorized />}
    </Suspense>
  )
}

export default RequireAuth