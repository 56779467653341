import React, { useEffect } from 'react'
import './Progress.css'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase'
import { db } from '../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { useSelector, useDispatch } from 'react-redux'
import { csnActions, authStateActions, clcsnActions, progressDataActions } from '../store'
import Loading from './Loading'
import ProgressCard from './ProgressCard'


function Progress(props) {

    const ud = useSelector(state => state.userData.userData)

    if(ud?.role === 'manager') {window.location.href = '/employee-center'}


    const linkStyles = {
        textDecoration: 'none'
    }

    let ren

    if(ud?.role == 'client') {
        ren = props.prog?.progress?.map((p, i) => {
            return (
                <Link to={`/progress/${p.service}`} style={linkStyles} key={i}>
                <ProgressCard key={i} progress={p} status={p.status} />
                </Link>
            )
        })
    }

    let renS

    if(ud?.role !== 'client') {
        renS = ud?.assignedClients.map((c, i) => {
            return (
                <Link to={`/progress/${c.name}`} style={linkStyles} key={i}>
                    <ProgressCard progress={{name: c.name, services: c.services}} key={i} status='active'/>
                </Link>
            )
        })
    }
    

    // useEffect(()=> {
    //     props.ml()
    // }, [auth.currentUser])



    if(true) {
        return (
            <div className='progress__cont'>
                <h2>Real-Time Progress</h2>

                
                {ud?.role == 'client' ?(props.prog !== 0? <div style={{display: 'flex', gap: '15px', marginInline: '20px'}}>{ren}</div> : 'No Progress Found!') : <div style={{display: 'flex', gap: '15px', marginInline: '20px'}}>{renS}</div>}
            </div>
        )
    } 
    else {
        return (
            <Loading />
        )
    }
}

export default Progress