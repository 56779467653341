import React, { useEffect, useState } from 'react'
import './Reports.css'
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import { useSelector } from 'react-redux'
import { storage } from '../firebase'
import { ref, uploadBytes, listAll, getMetadata } from 'firebase/storage'
import checkMark from '../images/green_checkmark.png'

function DisplayReports() {
    const [file, setFile] = useState(null)
    const [prevReps, setPrevReps] = useState([])
    const [repsMetadata, setRepsMetadata] = useState(null)
    const [specReps, setSpecReps] = useState([])
    const [sent, setSent] = useState(false)

    const ud = useSelector(state=> state.userData.userData)

    const part = window.location.pathname.split('/').slice(-2)
    const cl = part[0]?.trim().replace('%20', ' ').toLowerCase()
    const x = part.pop()
    const regex = /^[a-z]+/ig
    const y = x.match(regex)[0].toLowerCase().trim()
    let services
    let service
    let cn 

    ud?.assignedClients.map(c => {
          if(c.name.toLowerCase() == cl) {
            cn = c.cn
            services = c.services
          }
        })

        services?.map(s=> {
          if(s.split(' ')[0].toLowerCase().trim() == y) service = s.toLowerCase()
        })


    useEffect(()=> {
      if(ud) {
        const lastReportRef = ref(storage, `reports/${cn}/${service}/`)

      listAll(lastReportRef).then(res=> setPrevReps(res.items))
      getMetadata(lastReportRef).then(res=> setRepsMetadata(res))
      }
    }, [ud])
    
    
    let lastUpload = ud?.lastReports[cl.toLowerCase()][service]



function deepCopy(obj) {
  if(obj == null || typeof obj !== 'object') return obj

  const newObj = {}

  for(const key in obj) {
    if(obj.hasOwnProperty(key)) {
      newObj[key] = deepCopy(obj[key])
    }
  }

  return newObj
}


useEffect(()=> {
  if(prevReps.length !== 0) {
    let arr = []

    prevReps.map(r=> {
      let rName = r.name.toLowerCase().split('-').pop().match(/by (.+)/)[1]

      if(rName === ud?.name.toLowerCase()) {
        arr.push(r)
      }
    })

    setSpecReps(arr)
  }
}, [prevReps])



    async function postRep() {
      if(file) {
        // const rn = Math.floor(1000 + (9000 - 1000) * Math.random())
        const date = `${new Date().getDate()} ${new Date().toLocaleString('en', {month: 'short'})} ${new Date().getFullYear()}`
        // const fileName = file.name.split('.')[0] + rn
        const fileName = `Report - ${service} - ${date} - ${prevReps.length + 1}  -  by ${ud?.name}`
        const repRef = ref(storage, `/reports/${cn}/${service}/${fileName}`)
        const dbRepRef = doc(db, 'users', auth.currentUser.uid)
        

        uploadBytes(repRef, file)

        const repObj = deepCopy(ud?.lastReports)
        repObj[cl] = {}
        repObj[cl][service] = date
        updateDoc(dbRepRef, {lastReports: repObj})

        setSent(true)
        setFile(null)
      } else {
        alert('No file selected!')
      }
    }

    useEffect(()=> {
      const clearSent = setTimeout(()=> {
          setFile(null)
          setSent(false)
        }, 5000)
      

      return () => {
        clearTimeout(clearSent)
      }
    }, [sent])

  return (
    <div>
      {ud?.role !== 'client' && <a href={`/reports/${cl}`} style={{textDecoration: 'none'}}><p className='breadcrumbs'>{'<< Back'}</p></a>}
        <h2 className='reports__h2'>Upload Report</h2>

        <div className='reports__displayReports_cont'>
            <div className='reports__leftSide'>
              <p className='reports__ls_par'>Last report uploaded:  <span style={{color: 'dodgerblue'}}>{lastUpload == ''? 'No uploads': lastUpload}</span></p>
              <p className='reports__ls_par'>Total number of reports uploaded: <span style={{color: 'dodgerblue'}}>{specReps?.length}</span></p>
            </div>

            <div className='reports__rightSide'>
              <input type='file' accept='.pdf' onChange={event => setFile(event.target.files[0])} />
              <button className='reports__uploadBtn' onClick={postRep}>Submit</button>


              {sent && <div className='reports__sent'><img src={checkMark} style={{objectFit: 'contain', width: '27px'}} /> Report sent!</div>}
            </div>
        </div>
    </div>
  )
}

export default DisplayReports