import React, { useEffect, useRef, useState } from 'react'
import './ClientsDueLimits.css'
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import CDLDropdown from './CDLDropdown'
import Accordion from './Accordion'

function ClientsDueLimits() {
    const [cls, setCls] = useState([])
    const [newDL, setNewDL] = useState(null)


    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [openSection, setOpenSection] = useState(null)

    const toggleSection = (_i) => {
        setOpenSection(prev=> prev === _i? null : _i)
    }

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const updateAll = () => {
        let arr = [...cls]

        arr.forEach(cl => {
            cl.isUpdating = false
        })

        setCls(arr)
    }

    useEffect(()=> {
        const clRef = collection(db, 'users')
        let arr = []
        let arr2

        const getCl = async () => {
            const data = await getDocs(clRef)

            data.forEach(cl=> {
                arr.push({data: cl.data(), cn: cl.id, isUpdating: false})
            })

            arr2 = arr.filter(cl => cl.data.role === 'client')

            setCls(arr2)
        }

        if(cls.length === 0) getCl()
    }, [])

    const updateDL = async (_cn, _index) => {
        if(newDL !== '' && newDL !== null) {
            const userRef = doc(db, 'users', _cn)

            await updateDoc(userRef, {dueLimit: newDL})


            let arr = [...cls]

            arr[_index].isUpdating = false

            setCls(arr)
            setNewDL(null)
        }
    }
    

    const accordionSections = [
        { title: 'Rachel', content: 'Content for Section 1' },
        // Add more sections as needed
      ];
   

    
    let ren = cls?.map((cl, _index) => {

        
        // const dls = cl.data.dueLimit;
        // const dlComponents = dls?.split(' ');

        // const day = parseInt(dlComponents[0], 10);
        // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        // const month = monthNames.indexOf(dlComponents[1]);
        // const year = parseInt(dlComponents[2], 10);
        // const dl = new Date(year, month, day);

        // const tDiff = dl - currtDate;

        // const rem = Math.ceil(tDiff / (1000 * 60 * 60 * 24));

        // return (
        //     <div className='cdl__cont'>
        //         <p className='cdl__name'>{cl.data.name}</p>
        //         <p className='cdl__limit'>{cl.data.dueLimit}</p>
        //         <p className={`cdl__rem ${rem >= 20 ? 'cdl__green': (rem <20 && rem>= 15)? 'cdl__yellow': 'cdl__red'}`}>{rem}</p>
        //         <p className='cdl__status'>cl.</p>

        //         {!cls[_index].isUpdating && <button className='cdl__btn0' onClick={()=> updating(_index)}>Update</button>}
        //         {cls[_index].isUpdating && <div className='cdl__update_cont'>
        //             <input type='text' className='cdl__input' required value={newDL} onChange={event=> setNewDL(event.target.value)}/>
        //             <button className='cdl__btn' onClick={()=> updateDL(cl.cn, _index)}>Update Limit</button>
        //         </div>}
        //     </div>
        // )

        return (
        // <div className="dropdown-container">
        // <div className={`dropdown-header ${openSection === _index? 'open': ''}`} onClick={(_index)=> toggleSection(_index)}>
        //   {cl.data.name}
        // </div>
        // <CDLDropdown data={cl.data.archive} isDropdownOpen={isDropdownOpen} openSection={openSection} _index={_index} cls={cls} setCls={(x)=> setCls(x)}/>
        // </div>
        <>
        <Accordion cls={cls} _index={_index} setCls={setCls} data={cls[_index].data.archive} key={_index}/>
        </>
        )
    })



  return (
    <div style={{width: '80%', overflowX: 'hidden'}}>
        <div className='cdl__h2_cont'><h2 className='cdl__h2'>Clients Due Limits</h2> <h3 onClick={()=> updateAll()}>Re</h3></div>

        {<div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>{ren}</div>}
    </div>
  )
}

export default ClientsDueLimits