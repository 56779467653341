import React from 'react'
import WarningIcon  from '@mui/icons-material/Warning'
import './Unauthorized.css'

function Unauthorized() {
  return (
    <div className='unauth_cont'>
        <h2 className='unauth_h2'>Unauthorized!</h2>
        <WarningIcon className='unauth_icon'/>
    </div>
  )
}

export default Unauthorized