import React, { useEffect } from 'react'
import './Login.css'
import { useState } from 'react'
import { auth } from '../firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import logo from '../images/logo2.png'
import { useSelector, useDispatch } from 'react-redux'
import { csnActions } from '../store'
import { authStateActions } from '../store'
import bgPattern from '../images/energy-3357613.jpg'


function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)

    const cAuth = useSelector(state=> state.auth.authState)

    const dispatch = useDispatch()

    useEffect(()=> {
        const screenResize = () => {
            setIsDesktop(window.innerWidth >= 768)
        }

        window.addEventListener('resize', screenResize)

        return ()=> window.removeEventListener('resize', screenResize)
    }, [])
    

    const login = async () => {
        
        try{
            await signInWithEmailAndPassword(auth, email.trim(), password.trim())
            dispatch(authStateActions.setAuthState(true))
            
            window.location.href = '/progress'
        } catch (err) {
            alert(err)
        }
    }

if(!cAuth) {
  return (
    <>
        {/* <video muted loop autoPlay id='login-video-bg'>
            <source src={bgPattern} type='video/mp4' />
        </video> */}


        <div className='login' style={{backgroundImage: `url(${bgPattern})`}}>
            {isDesktop && <><div className="login-header">
               <a href='https://improwth.com'><img src={logo} id="login-logo" alt="Logo"/></a>
            </div>


            <div className="login-container">
                <div className='form'>
                    <label for="email">Email</label>
                    <input type="email" name="email" onChange={(e)=> setEmail(e.target.value)}/>

                    <label for="password">Password</label>
                    <input type="password" name="password" onChange={(e)=> setPassword(e.target.value)}/>

                    <button className='login-button' onClick={login}>Login</button>
                </div>
            </div></>}

            {!isDesktop && <h2 className='login_not_desk'>This application is only accessible from desktops</h2>}



            <div className="footer">
                <h5>All rights reserved</h5>
                <h5 id="name">Improwth</h5>
            </div>
        </div>
        </>
  )}
}

export default Login